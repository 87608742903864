/** @jsx jsx */
import { jsx } from '@emotion/core'
import cmsUtils from '../../cmsUtils';
import { Item } from '../../../../cms/items/item';
import utils from '../../../../utils';
import { useEffect, useState } from 'react';

export function Panel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const bgColor = cmsUtils.payload(item, 'BgColor');
  const imageUrl = cmsUtils.payload(item, 'ImageUrl');
  const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');
  const bgTheme = cmsUtils.payload(item, 'TextColorTheme') || 'white';
  let bgUrl = null;
  if (imageUrl)
    bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);

  const cssClass = utils.classNames('cms_item', 'contentPanel', 'contentPanel--' + bgTheme, item.cssClass || item.anchorName || '');
  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  // typing effects
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 30;
  
  useEffect(() => {
    let ticker = setInterval(()=>{
      tick();
    },delta)
    return () => { clearInterval(ticker) };
    // eslint-disable-next-line
  }, [text]);
  const tick = () => {
    let fullText = 'generate perfect AI property descriptions in seconds...';
    let updatedText = fullText.substring(0, text.length + 1);
    setText(updatedText);
    setDelta(period);
  };

  return (
    <section className={cssClass} style={{
      backgroundColor: `${bgColor}`,
      backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl)
    }} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className="contentPanel__bg"></div>
      <div className="container">
        <h1 className='typing_title'>{text}</h1>
        {subItemsElts}
      </div>
    </section>
  );
}
