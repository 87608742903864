import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';

export function BottomDock(props) {
  const links = [
    {
      url: '/about',
      title: 'about',
      icon: 'Nav-WHITE-Icon.png',
      iconHover: 'Nav-WHITE-Icon.png',
    },
    {
      url: '/#faq',
      title: 'faq',
      icon: 'Nav-WHITE-Icon.png',
      iconHover: 'Nav-WHITE-Icon.png',
    },
    {
      url: 'https://app.uplisted.com.au',
      title: 'sign in',
      icon: 'Nav-WHITE-Icon.png',
      iconHover: 'Nav-WHITE-Icon.png',
    },
    {
      url: '/blog',
      title: 'blog',
      icon: 'Nav-WHITE-Icon.png',
      iconHover: 'Nav-WHITE-Icon.png',
    },
    {
      url: '/#contact',
      title: 'contact',
      icon: 'Nav-WHITE-Icon.png',
      iconHover: 'Nav-WHITE-Icon.png',
    },
  ];

  const { pathname, location } = useRouter();

  const isCurrent = (link) => {
    if (pathname && pathname.toLowerCase() === link.toLowerCase()) {
      return true;
    } else if (
      location.hash &&
      location.hash.toLowerCase() === link.toLowerCase().substring(1)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className="icon">
          <img
            className="normal"
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className="selected"
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  return (
    <>
      <nav className="bottomMenu">
        <ul className="bottomMenu__links">{linkElts}</ul>
      </nav>
    </>
  );
}
